var videoPlayer = videojs('video-player');

landingInit();
/* proceedToPayment indicates whether a user in the modal flow should 
   continue to the payment screen 
*/
let proceedToPayment = false;
let currentUser = null;

function landingInit() {
   var ua = window.navigator.userAgent;
   var isIE = /MSIE|Trident/.test(ua);

   if (isIE) {
      var carouselIndicators = document.getElementsByClassName('carousel-indicator-li')
      for (var i = 0; i < carouselIndicators.length; i++) {
         var carouselIndicator = carouselIndicators[i];
         carouselIndicator.style.top = '6px';
      }
   }

   // If on IE or Chrome use the uncompressed video. This is being done here as a 
   // workaround for videojs's inability to play the compressed files correctly. The
   // real solution to this is to use a better video player (looks like HLS.js works) 
   // or possibly to lower the resolution of the compressed video.
   if (videojs.IE_VERSION !== null || videojs.IS_CHROME) {
      videoPlayer.src({ 
         type: "video/mp4", 
         src: "https://emcoach-audiovideos.s3.amazonaws.com/EM_Coach_homepage_video_spot_2000_questions.mp4" 
      });
   }
   // If on any other browser use the compressed video
   else {
      videoPlayer.src({ 
         type: "application/x-mpegURL", 
         src: "https://emcoach-audiovideos.s3.amazonaws.com/EM_Coach_homepage_video_spot_2000_questions.m3u8" 
      });
   }

   // If the user is on a mobile device, prompt them to use the mobile apps.
   // Only show this message if they haven't seen it before in this session.
   if (bowser.parse(window.navigator.userAgent).platform.type !== 'desktop') {
      const kShowMobileAppMarketingKey = 'showMobileAppMarketing';

      let showMobileAppMarketing = window.localStorage
         .getItem(kShowMobileAppMarketingKey);
      showMobileAppMarketing = showMobileAppMarketing === null ?
         true : showMobileAppMarketing === 'true';

      if (showMobileAppMarketing) {
         $('#mobileAppMarketing-modal').modal('show');
      }

      $('#mobileAppMarketing-modal').on('hide.bs.modal', () => {
         window.localStorage.setItem(kShowMobileAppMarketingKey, false);
      })
   }

   if (window.location.href.includes('paymentComplete')) {
      // If the "Payment Complete" modal should be displayed, then display it
      // and update the query string to ensure it won't appear again if the
      // user refreshes
      $('#paymentComplete-modal').modal('show');
      window.history.replaceState(null, '', '/');
   }
}

/* Handle registration form 
*  This will allow the user to create a new account and optionally,
*  based on the proceedToPayment go into the payment flow
*  /auth/register-email-ajax will log the new user in so when we are done
*  just reload the home page to bring them to learner home. 
*/
$('#registrationForm').submit(async event => {
   event.preventDefault();
   $("#formMessage").hide();

   var data = {}
   $('#registrationForm :input').each(function () {
      data[this.name] = $(this).val();
   })

   try {
      let response = await fetch('/auth/register-email-ajax', {
         method: 'POST',
         body: JSON.stringify(data),
         headers: { "Content-Type": "application/json" }
      })
      if (response.status === 200) {
         response.json().then((body) => {
            currentUser = body.user;
         });

         if (proceedToPayment) {
            // proceed to payment. The payment flow will reload the page
            // which will send new users to the registration form.
            $('#modal').hide();
            $('#payment-modal').show();
         } else {
            // backend should have logged new user in at this point
            // reloading should take them to learner home.
            location.reload();
         }
      } else {
         let { message } = await response.json();
         if (!message) {
            message = "There was a problem registering the account."
         }
         $("#formMessage").text(message);
         $("#formMessage").show();
      }
   } catch (error) {
      console.log(error);
      $("#formMessage").text("There was a problem registering the account.");
      $("#formMessage").show();
   }

   return false;
});

function dismissFullscreenPayment() {
   $("#payment-modal").hide()
   // clicking out here puts the user in a bad state-
   // they already have an account, and are logged in, which means
   // the buy now button won't work. So just send them to the home page
   // as an unpaid user.
   location.reload()
}

// Carousel
$('.carousel').carousel({
   interval: 5000
});

function openSignUpModal(buying) {
   // buying will set global flag to determine whether to continue the login flow
   // to the learner home or proceed through payment.
   proceedToPayment = buying

   document.getElementById('modal').style.display = 'block';
   document.getElementById('email-modal').style.display = 'block';
}

$('#modal-hitbox').click(function () {
   $('#modal').css('display', 'none');
});
$('#payment-modal-hitbox').click(function () {
   $('#payment-modal').css('display', 'none');
});

// Confirm email
const confirm_username = document.getElementById("confirm_username");
confirm_username.addEventListener("change", compareEmail);
function compareEmail(ev) {
   const inputConfirm = ev.srcElement;
   const input = document.getElementById('username');
   if (inputConfirm.value != input.value) {
      inputConfirm.setCustomValidity('Email addresses must match.');
   } else {
      inputConfirm.setCustomValidity('');
   }
}

// Confirm Password
const password2 = document.getElementById("password2");
password2.addEventListener("change", comparePassword);
function comparePassword(ev) {
   const inputConfirm = ev.srcElement;
   const input = document.getElementById('password');
   if (inputConfirm.value != input.value) {
      inputConfirm.setCustomValidity('Passwords do not match.');
   } else {
      inputConfirm.setCustomValidity('');
   }
}
